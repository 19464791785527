import React, {Component} from "react";
import CloseButton from "../sharedComponents/CloseButton";
import WelcomeWindowController from "./WelcomeWindowController";

class ConsentWindow extends Component {
  controller = new WelcomeWindowController(this.props.main);

  render() {

    const consentGiven = this.props.analyticsConsentGiven

    return (
      <div className={"welcome-window " + this.props.classNames ?? ''}>
        {<CloseButton
          closeButton={this.controller.closeConsentWindow}
        ></CloseButton>}

        <h3>Analytics</h3>
        <p>
          We use Google Analytics to gather information...

        </p>
        <p>
          Datenschutzhinweise siehe {" "}
          <a href="https://www.geoengineeringmonitor.org/about/">
            geoengineeringmonitor.org
          </a>
        </p>
        <input
          type="checkbox"
          id="analytics-consent"
          onChange={(changeEvent) =>
            this.controller.handleConsentChoice(
              changeEvent.currentTarget.checked
            )
          }
          checked={consentGiven}
        ></input>
        <label htmlFor="analytics-consent">
          {" "}
          Consent to analytics?
        </label>
      </div>
    );
  }
}

export default ConsentWindow;
