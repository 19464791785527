export default class WelcomeWindowController {
  app = {};

  constructor(app) {
    this.app = app;
    this.consent = false;
  }

  dontShowWelcomeWindowAgain = (checked) => {
    if (checked) {
      localStorage.setItem("showWelcomeMessage", false);
    } else {
      localStorage.removeItem("showWelcomeMessage");
    }
  };

  closeWelcomeWindow = () => {
    this.app.setState({ showWelcomeWindow: false });
  };

  closeConsentWindow = () => {
    localStorage.setItem("geoMap_analyticsConsentGiven", 'false');

    if(this.consent) {
      localStorage.setItem("geoMap_analyticsConsentGiven", 'true');
      this.app.maybeAddAnalytics()
    }
    this.app.setState({
      showAnalyticsConsentWindow: false,
      analyticsConsentGiven: this.consent });
  };


  handleConsentChoice = (consent) => {
    this.consent = consent

    this.app.setState({
      analyticsConsentGiven: consent,
    });
  }
}
