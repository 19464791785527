import React, { Component } from "react";
import { ReactComponent as IconX } from "../Images/Icon_X.svg";

class CloseButton extends Component {
  render() {
    return (
      <button
        className="button--close"
        onClick={() => {
          this.props.closeButton(false);
        }}
      >
        {!this.props.useCustomContent && <IconX></IconX> }
        {this.props.useCustomContent && <div>{this.props.useCustomContent}</div> }
      </button>
    );
  }
}

export default CloseButton;
