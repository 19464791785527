import React, { Component } from "react";
import "./aboutmap.scss";
import CloseButton from "../sharedComponents/CloseButton";
import {Button} from "@mui/material";

class AboutMapView extends Component {
  constructor(props) {
    super(props);
    this.app = props.main;
  }

  closeContainer = () => {
    this.app.setControlsContent({ hasControl: false });
  };
  render() {
    return (
      <div className="filter-view filter-view-about-map">
        <CloseButton closeButton={this.closeContainer}></CloseButton>
        <div className="header">
          <h4>Imprint</h4>
          <a href="https://www.boell.de/en/imprint" target="_blank" rel={'noreferrer'}>
            Legal Notice / Impressum
          </a>
          <p>A project of Heinrich Böll Foundation & ETC Group</p>
          <p>Editor: Anja Chalmin</p>

          {/*          <h4>Analytics</h4>
          <Button onClick={()=> {this.app.showConsentWindow()}}>Change Analytics Consent</Button>*/}

        </div>
        <div className="content">
          <h4>How do we collect information?</h4>
          <p>
            For more information on how the information for the Geoengineering
            Map is collected, please see the{" "}
            <a
              href="https://www.geoengineeringmonitor.org/2021/10/geoengineering-map-explainer/"
              target="_blank"
              rel={'noreferrer'}
            >
              Geoengineering Map Explainer
            </a>
            .
          </p>
          <h4>Liability</h4>
          <p>
            While substantial efforts are made to ensure the accuracy of data
            and documentation contained in this Data Set, complete accuracy of
            data and metadata cannot be guaranteed. The Data User holds all
            parties involved in the production or distribution of the Data Set
            harmless for damages resulting from its use. The Geoengineering Map
            has made a good faith effort in providing quality data but provides
            NO WARRANTY to the accuracy or usability of the data and accepts no
            liability for any errors or omissions.
          </p>{" "}
          <h4>
            Licence:{" "}
            <a
              href="https://creativecommons.org/licences/by-nc-nd/4.0/deed.en"
              target="_blank"
              rel={'noreferrer'}
            >
              cc-by-nd-nc 4.0
            </a>
          </h4>
          <p>
            This license covers e.g. comprehensive uses such as reproducing,
            distributing, exhibiting, performing, publicly playing and making
            publicly available a work and/or its contents, or adding it to a
            collection or a database without the work becoming commercially
            available.
          </p>
          <p>
            The work may not be adapted or altered. The names of the
            authors/creators must be stated in accordance with their wishes. In
            all other respects, a detailed explanation of rights and further
            provisions is provided in the valid version of the license
          </p>
        </div>
      </div>
    );
  }
}

export default AboutMapView;
